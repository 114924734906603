import React from 'react'

import { AuditHistory } from '../../components/views/history/auditHistory/auditHistory'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function () {
   return (
      <LayoutLoggedIn>
         <AuditHistory />
      </LayoutLoggedIn>
   )
}
